import { Power1 } from 'gsap';

export default function animatePopupBox(tl, element) {
  const o = 1;
  return tl.staggerFrom(
    element,
    0.9,
    {
      opacity: 0,
      // y: 20,
      ease: Power1.easeOut,
    },
    0,
    o
  );
}

export function animateDot(tl, element) {
  const o = 1.3;
  return tl.staggerFrom(
    element,
    o,
    {
      opacity: 0,
      ease: Power1.easeOut,
    },
    0,
    o
  );
}
