import React, { PureComponent } from 'react';
import { Container, Row, Col, Nav, Tab, Accordion, Card } from 'react-bootstrap';
import { TimelineLite, TweenLite, Linear } from 'gsap';
import { Link, graphql } from 'gatsby';
import Observer from 'react-intersection-observer';
import Img from 'gatsby-image';
import ReadMore from '../../static/assets/Readmore-white.svg';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import Yellowkey from '../../static/assets/yellow_key.svg';
import Minus from '../../static/assets/minus_white.svg';
import PlusHover from '../../static/assets/Plus_white.svg';
import '../sass/_our-opportunity-develop-and-grow.scss';
import * as globalAnimation from '../animations/globalAnimation';
import animatePopupBox, { animateDot } from '../animations/DevelopAndGrow';
import blueDot from '../../static/img/develop_and_grow/dot_blue.svg';
import yellowDot from '../../static/img/develop_and_grow/dot_yellow.svg';
import lineConnectorOne from '../../static/img/develop_and_grow/arc11.png';
import lineConnectorTwo from '../../static/img/develop_and_grow/arc22.png';
import lineConnectorThree from '../../static/img/develop_and_grow/arc33.png';
import lineConnectorFour from '../../static/img/develop_and_grow/arc44.png';

export default class DevelopAndGrow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayIcon1: true,
      displayIcon2: false,
      displayIcon3: false,
      displayIcon4: false,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    animateDot(this.tl, '.dot-one');
    const options = [
      {
        t: TweenLite.from('#target', 1, { autoAlpha: 0.1, paused: true, ease: Linear.easeNone }),
        scrollThreshold: { start: 100, stop: 600 },
      },
      {
        t: TweenLite.from('#target1', 1, { autoAlpha: 0.09, paused: true, ease: Linear.easeNone }),
        scrollThreshold: { start: 600, stop: 1000 },
      },
      {
        t: TweenLite.from('#target2', 1, { autoAlpha: 0.09, paused: true, ease: Linear.easeNone }),
        scrollThreshold: { start: 1200, stop: 1800 },
      },
      {
        t: TweenLite.from('#target3', 1, { autoAlpha: 0.09, paused: true, ease: Linear.easeNone }),
        scrollThreshold: { start: 1800, stop: 2400 },
      },
    ];
    globalAnimation.animateLine(this.tl, '.el');
    globalAnimation.fadeUpGrow(this.tl, '.animate-fade-up');
    globalAnimation.animateImageXRight(
      this.tl,
      '.svg-line__line_lightblue',
      '.svg_circle_lightblue',
      '.develop-image',
      '.lightblue_bg',
      '#title',
      '#desc',
      '#btn_anim',
      '100px',
      '0px'
    );

    options.forEach(i => {
      window.addEventListener('scroll', () => {
        const currentScroll =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const currentProgress =
          (currentScroll - i.scrollThreshold.start) /
          (i.scrollThreshold.stop - i.scrollThreshold.start);
        if (currentScroll > i.scrollThreshold.start && currentScroll < i.scrollThreshold.stop) {
          i.t.progress(currentProgress);
        } else if (currentScroll < i.scrollThreshold.start) {
          i.t.progress(0);
        } else if (currentScroll < i.scrollThreshold.stop) {
          i.t.progress(1);
        }
      });
    });
  }

  animateSecondHeading = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateHeading(tl, '.second-title');
    }
  };

  animateSectionTwo = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateImageXLeft(
        tl,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.develop-image2',
        '.orange_bg2',
        '#develop-title2',
        '#develop-description2',
        '#btn_anim',
        '-100px',
        '0px'
      );
    }
  };

  animateSectionThree = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateImageXLeft(
        tl,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.develop-image3',
        '.lightblue_bg3',
        '#develop-title3',
        '#develop-description3',
        '#btn_anim',
        '-100px',
        '0px'
      );
    }
  };

  animateSectionFour = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateImageXRight(
        tl,
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.develop-image4',
        '.orange_bg4',
        '#develop-title4',
        '#develop-description4',
        '#btn_anim',
        '100px',
        '0px'
      );
    }
  };

  animatePopupSection = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animatePopupBox(tl, '.popup-section');
    }
  };

  animateSectionFive = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.animateImageXLeft(
        tl,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.develop-image5',
        '.orange_bg5',
        '#develop-title5',
        '#develop-description5',
        '#btn_anim',
        '-100px',
        '0px'
      );
    }
  };

  render() {
    const { displayIcon1, displayIcon2, displayIcon3, displayIcon4 } = this.state;
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            title,
            containerOne,
            containerTwo,
            containerThree,
            containerFour,
            containerFive,
            bottomSection,
            bottomSection: {
              menu: { menuOne, menuTwo, menuThree, menuFour },
            },
          },
        },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="How Will I Develop And Grow Professionally?" />
        <div className="bg-appcolor">
          <Container fluid className="position-relative">
            <Row className="pt-5 pt-lg-0">
              <Col>
                <div className="wrapper px-0 develop-and-grow-title">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title white">
                      {title}
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle text-white px-0">
                      {title}
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              noGutters
              className="landing_wrapper develop-grow-container justify-content-center position-relative my-4"
            >
              <div className="d-none d-lg-block">
                <div className="position-absolute dot-one">
                  <img src={blueDot} alt="blue_line_dot" />
                </div>
                <div className="position-absolute connector-one el" id="target">
                  <img src={lineConnectorOne} alt="line-connector" />
                </div>
              </div>
              <Col xs={12} md={10} lg={{ span: 4, offset: 1 }} className="d-lg-none my-2">
                <Img fluid={containerOne.image.childImageSharp.fluid} />
              </Col>
              <Col xs={12} lg={4} className="d-none d-lg-block pb-lg-5" style={{ zIndex: 11 }}>
                <div className="bg-appcolor-light lightblue_bg">
                  <div className="position-relative">
                    <div>
                      <Img
                        className="img-develop develop-image cover_pic "
                        fluid={containerOne.image.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={12}
                lg={{ span: 5, offset: 3 }}
                className="pb-lg-5 mt-2 mt-lg-0 position-relative"
              >
                <div className="wrapper pl-0 py-0">
                  <div>
                    <h5 id="title" className="text-lightblue">
                      {containerOne.title}
                    </h5>
                  </div>
                  <div className="position-relative line line-horizontal pt-2">
                    <LightBluekey />
                  </div>
                  <p id="desc" className="text-white ">
                    {containerOne.descriptionOne}
                  </p>
                  <div className="page_heading_section text-center">
                    <div className="d-flex flex-column flex-lg-row justify-content-start align-items-center animate-fade-up">
                      <div>
                        <p className="text-white mb-0">Who will I work with & learn from? </p>
                      </div>
                      <div className="d-flex justify-content-center white-learnmore pl-lg-4">
                        <div className="py-3">
                          <Link to="/working-with/" className="bordered-btn text-center">
                            Learn more
                            <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid className="position-relative">
            <Observer onChange={this.animateSectionTwo} triggerOnce>
              <Row
                noGutters
                className="bg-appcolor develop-grow-container justify-content-center position-relative"
              >
                <div className="d-none d-lg-block">
                  <div className="position-absolute dot-two">
                    <img src={yellowDot} alt="yellow_line_dot" />
                  </div>
                  <div className="position-absolute connector-two" id="target1">
                    <img src={lineConnectorTwo} alt="line-connector" />
                  </div>
                </div>
                <Col xs={12} md={10} className="d-lg-none my-2">
                  <div>
                    <Img
                      className="img-fluid cover_pic"
                      fluid={containerTwo.image.childImageSharp.fluid}
                    />
                  </div>
                </Col>
                <Col
                  xs={12}
                  lg={{ span: 6, offset: 1 }}
                  className="description_column opecity0 mt-2 mt-lg-0"
                >
                  <div className="pl-0">
                    <h5 id="develop-title2" className="text-lightblue">
                      {containerTwo.title}
                    </h5>
                    <div className="position-relative line line-horizontal pt-2">
                      <Yellowkey />
                    </div>
                    <p className="text-white " id="develop-description2">
                      {containerTwo.description}
                    </p>
                  </div>
                </Col>
                <Col xs={12} lg={{ offset: 1, span: 4 }}>
                  <div className="bg-orange orange_bg2 d-none d-lg-block">
                    <div>
                      <Img
                        className="img-develop develop-image2 cover_pic"
                        fluid={containerTwo.image.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Observer>
          </Container>
          <Container fluid className="position-relative">
            <Observer onChange={this.animateSectionThree} triggerOnce>
              <Row
                noGutters
                className="bg-appcolor develop-grow-container justify-content-center position-relative"
              >
                <div className="d-none d-lg-block">
                  <div className="position-absolute dot-three">
                    <img src={blueDot} alt="blue_line_dot" />
                  </div>
                  <div className="position-absolute connector-three" id="target2">
                    <img src={lineConnectorThree} alt="line-connector" />
                  </div>
                </div>
                <Col xs={12} md={10} lg={{ span: 4, offset: 1 }} className="d-lg-none">
                  <Img fluid={containerThree.image.childImageSharp.fluid} />
                </Col>
                <Col xs={12} lg={{ span: 6, offset: 1 }}>
                  <div className="wrapper pl-0 py-0 mt-2 mt-lg-0">
                    <h5 id="develop-title3" className="text-lightblue">
                      {containerThree.title}
                    </h5>
                    <div className="position-relative line line-horizontal pt-2">
                      <LightBluekey />
                    </div>
                    <p id="develop-description3" className="text-white">
                      {containerThree.description}
                    </p>
                    <div className="d-flex white-learnmore">
                      <div className="py-3">
                        <Link
                          to="/our-opportunity-career-path/"
                          className="bordered-btn text-center"
                        >
                          Explore career paths
                          <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  lg={{ span: 4, offset: 1 }}
                  className="d-none d-lg-block"
                  style={{ zIndex: 11 }}
                >
                  <div className="bg-appcolor-light lightblue_bg3">
                    <div className="">
                      <Img
                        className="img-develop develop-image3 cover_pic"
                        fluid={containerThree.image.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Observer>
          </Container>
          <Container fluid className="position-relative">
            <Observer onChange={this.animateSectionFour} triggerOnce>
              <Row
                noGutters
                className="bg-appcolor develop-grow-container justify-content-center position-relative"
              >
                <div className="d-none d-lg-block">
                  <div className="position-absolute dot-four">
                    <img src={yellowDot} alt="blue_line_dot" />
                  </div>
                  <div className="position-absolute connector-four" id="target2">
                    <img src={lineConnectorFour} alt="line-connector" />
                  </div>
                </div>
                <Col xs={12} md={10} lg={{ span: 4, offset: 1 }} className="d-lg-none">
                  <Img fluid={containerFour.image.childImageSharp.fluid} />
                </Col>
                <Col xs={12} lg={4} className="d-none d-lg-block">
                  <div className="bg-orange orange_bg4">
                    <div className="">
                      <Img
                        className="img-develop develop-image4 cover_pic"
                        fluid={containerFour.image.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} lg={{ span: 6, offset: 1 }}>
                  <div className="wrapper pl-0 mt-2 mt-lg-0">
                    <h5 id="develop-title4" className="text-lightblue">
                      {containerFour.title}
                    </h5>
                    <div className="position-relative line line-horizontal pt-2">
                      <Yellowkey />
                    </div>
                    <p id="develop-description4" className="text-white ">
                      {containerFour.description}
                    </p>
                  </div>
                </Col>
                <div className="position-absolute dot-five d-none d-lg-block">
                  <img src={blueDot} alt="blue_line_dot" />
                </div>
              </Row>
            </Observer>
          </Container>
          <Container fluid className="position-relative">
            <Observer onChange={this.animateSectionFive} triggerOnce>
              <Row
                noGutters
                className="bg-appcolor mt-lg-0 h-100 justify-content-center position-relative"
              >
                <Col xs={12} md={10} className="d-lg-none">
                  <Img fluid={containerFive.image.childImageSharp.fluid} />
                </Col>
                <Col xs={12} lg={{ span: 5 }}>
                  <div className="mt-3 mt-lg-0 px-lg-5">
                    <h5 id="develop-title5" className="text-lightblue">
                      {containerFive.title}
                    </h5>
                    <div className="position-relative line line-horizontal pt-2">
                      <LightBluekey />
                    </div>
                    <p className="text-white" id="develop-description5">
                      {containerFive.descriptionOne}
                    </p>
                    <p id="develop-description5" className="text-white">
                      {containerFive.descriptionTwo}
                    </p>
                  </div>
                </Col>
                <Col xs={12} lg={{ span: 4, offset: 3 }}>
                  <div className="bg-orange orange_bg5 d-none d-lg-block">
                    <div className="">
                      <Img
                        className="img-develop develop-image5 cover_pic"
                        fluid={containerFive.image.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Observer>
          </Container>
        </div>
        <Container fluid>
          <Row noGutters>
            <Col xs={12}>
              <Row className="bg-appcolor">
                <Col>
                  <div className="center">
                    <section className="develop-grow-card">
                      <Row className="bg-grey">
                        <Col>
                          <Observer onChange={this.animateSecondHeading} triggerOnce>
                            <div className="wrapper pl-0 pr-0">
                              <div className="page_heading_section text-center">
                                <h4 className=" page_heading_child second-title mx-auto">
                                  {bottomSection.title}
                                </h4>
                              </div>
                            </div>
                          </Observer>
                          <Container>
                            <Observer onChange={this.animatePopupSection} triggerOnce>
                              <div className="py-5 d-none d-lg-block popup-section">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                  <Row>
                                    <Col xs={6} lg={6} className="bg-appcolor py-3 pl-0 pr-0">
                                      <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                          <Nav.Link eventKey="first" className="py-4">
                                            <h4 className="py-2 pl-4">{menuOne.title}</h4>
                                          </Nav.Link>
                                          <div className="nav-bottom-border" />
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="second" className="py-4">
                                            <h4 className="py-2 pl-4">{menuTwo.title}</h4>
                                          </Nav.Link>
                                          <div className="nav-bottom-border" />
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="three" className="py-4">
                                            <h4 className="py-2 pl-4">{menuThree.title}</h4>
                                          </Nav.Link>
                                          <div className="nav-bottom-border" />
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="four" className="py-4">
                                            <h4 className="py-2 pl-3">{menuFour.title}</h4>
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </Col>
                                    <Col xs={6} lg={6}>
                                      <Tab.Content className="ml-3">
                                        <Tab.Pane eventKey="first">
                                          <Row className="bg-white pl-3 h-popover">
                                            <Col xs={12} lg={6}>
                                              <div className="popover-arrow">
                                                <div className="one" />
                                              </div>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuOne.listOne.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuOne.listTwo.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                          <Row className="bg-white pl-3 h-popover">
                                            <Col xs={12} lg={6}>
                                              <div className="popover-arrow">
                                                <div className="two" />
                                              </div>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuTwo.listOne.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuTwo.listTwo.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="three">
                                          <Row className="bg-white pl-3 h-popover">
                                            <Col xs={12} lg={6}>
                                              <div className="popover-arrow">
                                                <div className="three" />
                                              </div>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuThree.listOne.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuThree.listTwo.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="four">
                                          <Row className="bg-white pl-3 h-popover">
                                            <Col xs={12} lg={6}>
                                              <div className="popover-arrow">
                                                <div className="four" />
                                              </div>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuFour.listOne.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                              <div className="circle-light-bullets-small">
                                                <ul id="lists" className="lightblue-bullets">
                                                  {menuFour.listTwo.map((list, index) => (
                                                    <li key={String(index)} className="py-2">
                                                      {list}
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </Col>
                                  </Row>
                                </Tab.Container>
                              </div>
                            </Observer>
                          </Container>
                          <div className="d-lg-none py-4">
                            <Col xs={12} md={8}>
                              <Accordion defaultActiveKey="0">
                                <Card className="mb-3">
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    onClick={() => {
                                      this.setState({
                                        displayIcon1: !displayIcon1,
                                        displayIcon2: false,
                                        displayIcon3: false,
                                        displayIcon4: false,
                                      });
                                    }}
                                    eventKey="0"
                                    className={`p-4 ${
                                      displayIcon1
                                        ? 'accordion-header-true'
                                        : 'accordion-header-false'
                                    }`}
                                  >
                                    <div className="px-2 px-md-4 d-flex justify-content-between">
                                      <h6 className="text-bold mb-0 d-flex align-items-center">
                                        {menuOne.title}
                                      </h6>

                                      {displayIcon1 === false ? (
                                        <PlusHover
                                          style={{ width: '18px', height: '18px' }}
                                          alt=""
                                        />
                                      ) : null}
                                      {displayIcon1 === true ? (
                                        <Minus style={{ width: '18px', height: '18px' }} alt="" />
                                      ) : null}
                                      {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="0" className="px-2 px-md-4">
                                    <Card.Body>
                                      <Row className="bg-white">
                                        <Col xs={12} lg={6} className="px-0">
                                          <div className="popover-arrow">
                                            <div className="one" />
                                          </div>
                                          <div className="circle-light-bullets-small">
                                            <ul>
                                              {menuOne.listOne.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                              {menuOne.listTwo.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                                <Card className="mb-3">
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    onClick={() => {
                                      this.setState({
                                        displayIcon2: !displayIcon2,
                                        displayIcon1: false,
                                        displayIcon3: false,
                                        displayIcon4: false,
                                      });
                                    }}
                                    eventKey="1"
                                    className={`p-4 ${
                                      displayIcon2
                                        ? 'accordion-header-true'
                                        : 'accordion-header-false'
                                    }`}
                                  >
                                    <div className="px-2 px-md-4 d-flex justify-content-between">
                                      <h6 className="text-bold mb-0 d-flex align-items-center">
                                        {menuTwo.title}
                                      </h6>
                                      {displayIcon2 === false ? (
                                        <PlusHover
                                          style={{ width: '18px', height: '18px' }}
                                          alt=""
                                        />
                                      ) : null}
                                      {displayIcon2 === true ? (
                                        <Minus style={{ width: '18px', height: '18px' }} alt="" />
                                      ) : null}
                                      {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="1" className="px-2 px-md-4">
                                    <Card.Body>
                                      <Row className="bg-white">
                                        <Col xs={12} lg={6} className="px-0">
                                          <div className="popover-arrow">
                                            <div className="two" />
                                          </div>
                                          <div className="circle-light-bullets-small">
                                            <ul>
                                              {menuTwo.listOne.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                              {menuTwo.listTwo.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                                <Card className="mb-3">
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    onClick={() => {
                                      this.setState({
                                        displayIcon3: !displayIcon3,
                                        displayIcon1: false,
                                        displayIcon2: false,
                                        displayIcon4: false,
                                      });
                                    }}
                                    eventKey="2"
                                    className={`p-4 ${
                                      displayIcon3
                                        ? 'accordion-header-true'
                                        : 'accordion-header-false'
                                    }`}
                                  >
                                    <div className="px-2 px-md-4 d-flex justify-content-between">
                                      <h6 className="text-bold mb-0 d-flex align-items-center">
                                        {menuThree.title}
                                      </h6>
                                      {displayIcon3 === false ? (
                                        <PlusHover
                                          style={{ width: '18px', height: '18px' }}
                                          alt=""
                                        />
                                      ) : null}
                                      {displayIcon3 === true ? (
                                        <Minus style={{ width: '18px', height: '18px' }} alt="" />
                                      ) : null}
                                      {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="2" className="px-2 px-md-4">
                                    <Card.Body>
                                      <Row className="bg-white">
                                        <Col xs={12} lg={6} className="px-0">
                                          <div className="popover-arrow">
                                            <div className="three" />
                                          </div>
                                          <div className="circle-light-bullets-small">
                                            <ul>
                                              {menuThree.listOne.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                              {menuThree.listTwo.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                                <Card>
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    onClick={() => {
                                      this.setState({
                                        displayIcon4: !displayIcon4,
                                        displayIcon1: false,
                                        displayIcon2: false,
                                        displayIcon3: false,
                                      });
                                    }}
                                    eventKey="3"
                                    className={`p-4 ${
                                      displayIcon4
                                        ? 'accordion-header-true'
                                        : 'accordion-header-false'
                                    }`}
                                  >
                                    <div className="px-2 px-md-4 d-flex justify-content-between">
                                      <h6 className="text-bold mb-0 d-flex align-items-center">
                                        {menuFour.title}
                                      </h6>
                                      {displayIcon4 === false ? (
                                        <PlusHover
                                          style={{ width: '18px', height: '18px' }}
                                          alt=""
                                        />
                                      ) : null}
                                      {displayIcon4 === true ? (
                                        <Minus style={{ width: '18px', height: '18px' }} alt="" />
                                      ) : null}
                                      {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey="3" className="px-2 px-md-4">
                                    <Card.Body>
                                      <Row className="bg-white">
                                        <Col xs={12} lg={6} className="px-0">
                                          <div className="circle-light-bullets-small">
                                            <ul>
                                              {menuFour.listOne.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                              {menuFour.listTwo.map((list, index) => (
                                                <li
                                                  key={String(index)}
                                                  className="m-0 pl-2 text-medium"
                                                >
                                                  {list}
                                                </li>
                                              ))}
                                            </ul>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              </Accordion>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    </section>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "our-opportunity-develop-and-grow" } }) {
      frontmatter {
        title
        containerOne {
          title
          descriptionOne
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerTwo {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerThree {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerFour {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        containerFive {
          title
          descriptionOne
          descriptionTwo
          image {
            childImageSharp {
              fluid(maxWidth: 1048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        bottomSection {
          title
          menu {
            menuOne {
              title
              listOne
              listTwo
            }
            menuTwo {
              title
              listOne
              listTwo
            }
            menuThree {
              title
              listOne
              listTwo
            }
            menuFour {
              title
              listOne
              listTwo
            }
          }
        }
      }
    }
  }
`;
